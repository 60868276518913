@import url(normalize.css);
@import url(colors.css);
@import url(vars.css);
@import url(wysiwyg.css);

*,
::after,
::before {
    box-sizing: inherit;
}

html {
    overflow: initial;
}

body {
    box-sizing: border-box;
    background-color: var(--c-white);
    color: var(--c-grey3);
    font-weight: var(--f-normal);
    font-size: 14px;
    font-family: var(--base-font);
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    color: inherit;
    font-size: inherit;
}

table {
    font-size: inherit;
}

a:any-link {
    color: var(--c-blue);
    text-decoration: none;
}

.noPrint {
    @media print {
        display: none !important;
    }
}

#loadedMark,
#loadingStart {
    display: none;
}

@media print {
    @page {
        size: a3 landscape;
    }

    * {
        animation: none !important;
    }

    html {
        -webkit-print-color-adjust: exact !important;
    }
}

.jscolor-btn-close:hover {
    cursor: pointer !important;
}

.jscolor-btn-close:hover span {
    cursor: pointer !important;
}

.ReactModalPortal {
    /* fixes overlay stacking contexts */
    position: relative;
    z-index: 100000;
}

.fileManager {
    /* fixes overlay stacking contexts */
    position: relative;
    z-index: 2000000;
}

#notify {
    position: relative;
    z-index: 200000;
}

#popper {
    position: relative;
    z-index: 500000;
}

#popper.popperPortal {
    z-index: 550000;
}

#popup2 {
    position: relative;
    z-index: 600000;
}

#settingsPopup {
    position: relative;
    z-index: 500000;
}

#popoutActions {
    position: relative;
    z-index: 800000;
}

#rdp-portal {
    position: relative;
    z-index: 200000;
}

h1 {
    margin-bottom: 1.5em;
    font-weight: var(--f-bold);
    font-size: var(--t-level1);
}
