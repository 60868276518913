.smile {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.small {
        @media (--medium-until) {
            width: 70px;
            height: 70px;
        }
    }

    svg {
        width: 100%;
        height: auto;
    }

    &.small.pack2 svg {
        width: 48px;

        @media (--medium-until) {
            width: 62px;
        }
    }

    &.small.pack3 svg {
        width: 46px;

        @media (--medium-until) {
            width: 58px;
        }
    }

    &.large {
        width: 90px;
        height: 90px;

        @media (--component-break1) {
            width: 50px;
            height: 50px;
        }
    }

    &.large.pack2 svg {
        width: 78px;
    }

    &.large.pack3 svg {
        width: 72px;
    }
}
