.diff {
    width: 100%;
    max-width: 100%;

    @media (--medium) {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: minmax(50px, 1fr) 5fr minmax(50px, 1fr);
        gap: 1ch;
        align-items: center;
    }

    p {
        margin: 0;
    }
}

.answer {
    @media (--medium) {
        display: contents;
    }

    .name {
        font-weight: var(--f-bold);

        @media (--medium) {
            grid-column: 1 / 2;
        }
    }

    .field_invalid {
        border-radius: var(--b-border-radius);
        background: var(--c-red_a_1a);
    }

    .field {
        display: flex;
        flex-grow: 1;
        justify-content: space-around;
        align-items: center;
        padding: 1ch;
        text-align: left;

        @media (--medium-until) {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @media (--medium) {
            display: contents;
        }

        .label {
            font-weight: var(--f-thiner);

            &:first-child {
                margin-right: 40px;

                @media (--medium-until) {
                    order: 1;
                    width: 40%;
                    margin-right: 0;
                }

                @media (--medium) {
                    grid-column: 1 / 1;
                    margin-right: 0;
                }
            }

            &:not(:first-child) {
                display: flex;
                justify-content: flex-start;
                width: 20vw;
                margin-left: 40px;
            }

            &:last-child {
                width: auto;
                text-align: left;
                overflow-wrap: anywhere;

                @media (--medium-until) {
                    display: flex;
                    justify-content: flex-end;
                    order: 2;
                    width: 40%;
                    margin: 0;
                    text-align: right;
                }

                @media (--medium) {
                    grid-column: 3 / -1;
                    margin-left: 0;
                }
            }
        }

        .scale {
            display: flex;
            justify-content: space-around;
            align-items: center;
            user-select: none;

            @media (--medium-until) {
                order: 3;
                min-width: 100%;
                padding-top: 1ch;
            }

            @media (--medium) {
                grid-column: 2 / 2;
            }

            .item {
                display: flex;
                align-content: stretch;
                justify-content: center;
                align-items: center;
                border: 2px solid transparent;
                font-weight: var(--f-bolder2);
                text-align: center;
                cursor: pointer;

                @media (--medium) {
                    min-width: 35px;
                    height: 35px;
                }

                @media (--medium-until) {
                    min-width: 26px;
                    height: 26px;
                    padding-right: 5px;
                    padding-left: 5px;
                    line-height: 22px;

                    &:first-child {
                        margin-left: -8px;
                    }

                    &:last-child {
                        margin-right: -8px;
                    }
                }

                &.selected {
                    border-color: var(--c-blue);
                    border-radius: var(--b-border-radius);
                }
            }
        }
    }
}
