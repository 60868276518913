.list {
    @mixin flexVList var(--list-gap);

    @media (--medium-until) {
        width: 100%;
    }

    .line {
        &.hidden {
            visibility: hidden;
        }

        .control {
            min-width: 20ch;
        }

        .field {
            flex-grow: 1;
            min-height: 2em;

            @media (--medium) {
                max-width: 450px;
            }

            @media (--medium-until) {
                width: 100%;
            }
        }
    }
}
