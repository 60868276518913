.agreementPopup {
    position: fixed;
    right: 3vw;
    bottom: 3vw;
    z-index: 1000;
    max-width: 320px;
    padding: clamp(14px, 2vw, 25px);
    border-radius: clamp(14px, 2vw, 25px);
    background-color: var(--c-white);
    box-shadow: 0 4px 10px #2e5bff33;
    font-size: 14px;
    line-height: 1.4;

    @media (--component-break1) {
        left: 3vw;
        max-width: 100%;
    }
}
