.field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: max-content;
    height: 100%;

    @media (--medium-until) {
        min-width: 100%;
    }

    @media (--medium) {
        gap: 1.5ch;
        min-width: 100%;
    }

    @media (--large-until) {
        order: 3;
        width: min-content;
        padding-top: 10px;
    }

    .item {
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        width: fit-content;
        min-width: 3ch;
        height: 3ch;
        padding: 0 0.6ch;
        font-weight: var(--f-bolder2);
        text-align: center;
        cursor: pointer;
        user-select: none;

        @media (--medium-until) {
            min-width: 26px;
        }

        p {
            margin: 0;
            font-weight: var(--f-bolder2);
        }

        &.selected {
            border-radius: var(--b-border-radius);
        }
    }
}

.cantRateCheckbox {
    place-content: start;
    margin-top: 18px;
}

.container {
    display: flex;
    flex-direction: column;
}

.scaleTypeNumber,
.scaleTypeNumber :global(.question),
.scaleTypeNumber :global(.scroll) {
    width: 100%;
}

.scaleTypeNumberLessThanFour,
.scaleTypeNumberLessThanFour :global(.question),
.scaleTypeNumberLessThanFour :global(.scroll) {
    width: max-content;
    min-width: 170px;
}

.leftRight :global(.answer) {
    margin-top: 2ch;
    padding: 0 1ch;
}

.leftRight {
    display: flex;
}

.leftRight :global(.scroll) {
    flex: unset;
}

.leftRightSlider :global(.scroll) {
    flex: 1 1 auto;
}
