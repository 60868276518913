.textfield {
    position: relative;

    input::placeholder {
        font-weight: inherit;
        font-style: inherit;
        font-size: inherit;
        font-family: inherit;
        letter-spacing: inherit;
    }

    .multiline {
        display: block; /* https://bugs.chromium.org/p/chromium/issues/detail?id=89530 */
        overflow: hidden;
    }

    &.withIcon {
        .icon {
            @mixin flexCenter;
            position: absolute;
            width: 2em;
            height: 100%;

            svg {
                width: 100%;
                height: auto;
            }
        }

        &.iconStart {
            .control {
                padding-left: 2.5em;
            }

            .icon {
                left: 0.75em;
            }
        }

        &.iconEnd {
            .control {
                padding-right: 2.25em;
            }

            .icon {
                right: 0.75em;
                width: 1.25em;
            }
        }
    }

    .control {
        width: 100%;
        height: 34px;
        padding: 6px 10px;
        border: var(--border-width-thin) solid transparent;
        border-radius: 8px;
        background: var(--c-border);
        outline: none;
        font-weight: var(--f-normal);
        resize: none;

        &.error {
            background-color: var(--c-error-bg, var(--c-error));
        }

        &:disabled {
            background-color: var(--c-greyF4);
        }

        &.transparent {
            background: transparent;
        }

        &::placeholder {
            color: currentColor;
            opacity: 0.4;
        }

        &[type='number']::-webkit-inner-spin-button,
        &[type='number']::-webkit-outer-spin-button {
            margin: 0;
            appearance: none;
        }

        &[type='number'] {
            appearance: textfield;
        }

        &:focus {
            border-color: var(--c-border-focus) !important;
        }
    }
}
