.wysiwyg::after {
    content: '';
    display: block;
    clear: both;
}

.wysiwyg figure {
    clear: both;
    width: 100%;
    margin: 0;
}

.wysiwyg figure img {
    width: 100%;

    @media (--medium-until) {
        width: 100% !important;
    }
}

.wysiwyg .image.image_resized img {
    width: 100%;

    @media (--medium-until) {
        width: 100% !important;
    }
}

.image.image_resized {
    @media (--medium-until) {
        width: 100% !important;
    }
}

.wysiwyg .image.image-style-align-left {
    float: left;
    margin-right: 1.5rem;
}

.wysiwyg .image.image-style-align-center {
    margin-right: auto !important;
    margin-left: auto !important;
}

.wysiwyg .image.image-style-align-right {
    float: right;
    margin-left: 1.5rem;
}

.wysiwyg .image.image-style-side {
    float: right;
    max-width: 50%;
    margin-left: 1.5rem;
}

.wysiwyg figure table {
    width: 100%;
    height: 100%;
}

.wysiwyg figure table figure {
    width: 100%;
    height: 100%;
}

.wysiwyg ol,
.wysiwyg ul,
.wysiwyg li {
    margin: revert;
    padding: revert;
    list-style: revert;
    font-size: 1rem;
}

.wysiwyg strong {
    font-weight: var(--f-bold);
}
