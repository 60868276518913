.headerWrap {
    z-index: 3000;
    transform: translate3d(0, 0, 0);

    &.sticky {
        position: sticky;
        top: 0;
    }
}

.header {
    position: relative;
    z-index: 3;
    margin: 0 auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (--medium-until) {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        height: auto !important;
    }

    .logoWrap {
        position: absolute;
        width: 100%;
        height: 100%;

        @media (--medium-until) {
            position: static !important;
            display: flex;
            justify-content: center;
            order: 1;

            img {
                position: static !important;
                right: initial !important;
                width: 100% !important;
                max-width: 70vw;
                height: 100% !important;
                max-height: 20vh;
            }
        }

        .logoImg {
            position: absolute;
            object-fit: contain;
        }
    }
}
