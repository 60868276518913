.nav {
    @mixin flexHList 1em;
    flex-wrap: wrap;

    .nextButton {
        padding-right: 60px;
        padding-left: 60px;
    }

    .action {
        min-width: 165px;

        @media (--component-break1) {
            flex-grow: 1;
            min-width: auto;
        }

        button {
            width: auto !important;
        }
    }

    .backButtonWrap {
        min-width: auto;
    }
}
