.name {
    display: grid;
    gap: 2ch;
    align-items: center;
    width: 100%;

    @media (--medium-until) {
        display: flex;
        flex-direction: column;
    }
}

.row {
    display: flex;
    flex-direction: column;
    gap: 2ch;

    @media (--medium) {
        display: contents;
        align-items: center;

        &.noLabel {
            grid-template-columns: auto;
        }
    }

    .fields {
        display: flex;
        flex: 1 1 auto;
        flex-wrap: wrap;
        gap: 2ch;
        justify-content: space-evenly;

        @media (--medium) {
            display: contents;
            align-items: center;
        }
    }

    .cell {
        @media (--medium) {
            min-width: 10ch;
        }

        &.label {
            word-break: break-word;

            p {
                margin: 0;
            }
        }

        &.answer {
            @media (--medium) {
                flex-basis: calc(33% - 2ch);
            }

            @media (--medium-until) {
                width: 100%;
            }

            .field {
                @media (--medium) {
                    display: flex;
                    flex-basis: calc(33% - 1ch);
                }

                .text {
                    @media (--medium) {
                        flex-grow: 1;
                        min-width: 12ch;
                    }
                }
            }
        }
    }
}
