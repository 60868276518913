.additionalImageWrap {
    position: relative;
    display: inline-flex;

    img {
        width: 100%;
        height: auto;
        border-radius: var(--b-border-radius-2);
    }
}

.zoomIcon {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 10;
    max-width: 2rem;
    max-height: 2rem;
    color: var(--c-blue);
    cursor: pointer;
}

.helpText {
    font-size: clamp(12px, 12px + 2vw, 16px);
}
