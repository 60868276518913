.image {
    display: block;
    width: 100%;
    max-width: 739px;
    height: auto;
    margin: 0 auto;

    @media (--large-until) {
        max-width: 580px;
    }
}

.head {
    color: var(--c-grey3);
    font-weight: var(--f-normal);
    font-style: normal;
    font-size: 30px;
    font-family: Montserrat, sans-serif;
    line-height: 37px;
    text-align: center;
}

.buttonWrap .button {
    min-width: 238px;
    margin: 50px auto 0;

    @media (--large-until) {
        min-width: 234px;
        margin-top: 60px;
    }

    @media (--medium-until) {
        min-width: 188px;
        margin-top: 20px;
    }
}
