.container {
    display: flex;
    align-items: center;

    .label {
        margin-left: 10px;
        font-size: 1.3rem;

        @media (--medium-until) {
            font-size: 1rem;
        }
    }
}

.agreementLink {
    color: var(--c-blue);
    font-weight: var(--f-bolder2);
}
