.list {
    display: grid;
    gap: 2ch;
    align-items: center;
    width: 100%;

    @media (--medium-until) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .requiredText {
        margin-left: 1ch;
        color: var(--c-red);
        font-weight: var(--f-thiner);
    }

    .answer {
        display: flex;
        gap: 2ch;

        @media (--medium) {
            display: contents;
            align-items: center;
        }

        @media (--medium-until) {
            flex-direction: column;
            flex-wrap: wrap;
            width: 100%;
        }

        .label {
            font-weight: var(--f-thiner);
            word-break: break-word;

            p {
                margin: 0;
            }
        }

        .field {
            @media (--medium) {
                flex-grow: 1;
                max-width: 30ch; /* https://www.freshaddress.com/blog/long-email-addresses/ */
            }

            @media (--medium-until) {
                width: 100%;
            }
        }
    }
}
