/* https://jsfiddle.net/wd87f9qb/ */

/* TODO move to ui */

/* TODO check for hsl */

/* TODO use in tsx */

/*
TODO collect all rgb
    "function-blacklist": ["/^rgb/", "/^hsl/", "gray"],
    https://medium.com/swlh/writing-your-first-custom-stylelint-rule-a9620bb2fb73
*/

/* TODO https://donatstudios.com/CSS-Alike-Color-Finder */

/* stylelint-disable color-no-hex */

:root {
    --c-action-bg: #1abc9c;
    --c-action-preview-bg: #f7fafecc;
    --c-alert: #f53d67;
    --c-black: #000;
    --c-black_a1a: #0000001a;
    --c-black_a80: #00000080;
    --c-black_a9: #0009;
    --c-black_a_3: #0003;
    --c-black_a_4d: #0000004d;
    --c-blue-a07: #2e5bff09;
    --c-blue-a1a: #2e5bff1a;
    --c-blue-a26: #2e5bff26;
    --c-blue-a4d: #2e5bff4d;
    --c-blue-a80: #2e5bff80;
    --c-blue-border: #97c7ff;
    --c-blue-end: #92E2FB;
    --c-blue-middle: #67bffe;
    --c-blue-nps: #538cf7;
    --c-blue-pale-boreder: #d5e9ff;
    --c-blue-star: #3087f9;
    --c-blue2: #318fff;
    --c-blue2_1a: #318fff1a;
    --c-blue2_33: #318fff33;
    --c-blue2_a_3d: #318fff3d;
    --c-blue2_a_4d: #318fff4d;
    --c-blue2_a_59: #318fff59;
    --c-blue2_a_73: #318fff73;
    --c-blue2_a_bf: #318fffbf;
    --c-blue2_a_cc: #318fffcc;
    --c-blue6: #8fbcff;
    --c-blue6_a_3d: #8fbcff3d;
    --c-blue6_a_66: #8fbcff66;
    --c-blue7: #2e5bff;
    --c-blue: #3190ff;
    --c-blue_1a: #3190ff1a;
    --c-blue_a_80: #3190ff80;
    --c-blue_a_33: #3190ff33;
    --c-blue_a_cc: #3190ffcc;
    --c-border-focus: #47a4f5;
    --c-border: #eaf4ff;
    --c-border_a_80: #eaf4ff80;
    --c-btn-hover: #2b79d4;
    --c-btn-hover_a_33: #2b79d40d;
    --c-btn-hover_a_od: #2b79d40d;
    --c-ck-promocode-bg: #8a7676;
    --c-ck-tooltip: #222126;
    --c-disabled: #e0e0e0;
    --c-disale-tap: #fff0;
    --c-error-bg: #feebef;
    --c-error: #eb5757;
    --c-green: #6fcf97;
    --c-grey3-80: #33333380;
    --c-grey3: #333;
    --c-grey8D: #8d8d8d;
    --c-grey9: #999;
    --c-greyC: #ccc;
    --c-greyE: #eee; /* 5 */
    --c-greyF4: #f4f4f4; /* 11 */
    --c-greyG: #C3C3C3;
    --c-img-add: #fdc3ce;
    --c-light-blue: #76dafa;
    --c-link: #4583c4;
    --c-main-menu-num: #00eaf8;
    --c-main-menu-num_a_33: #00eaf833;
    --c-msg-success: #52c41a;
    --c-picker-default10: #50e3c2;
    --c-picker-default1: #d0021b;
    --c-picker-default2: #f5a623;
    --c-picker-default3: #f8e71c;
    --c-picker-default4: #8b572a;
    --c-picker-default5: #7ed321;
    --c-picker-default6: #417505;
    --c-picker-default7: #bd10e0;
    --c-picker-default8: #9013fe;
    --c-picker-default9: #4a90e2;
    --c-purple-bg-dark: #9495f8;
    --c-purple-bg: #93b4f9;
    --c-red: #f9385a;
    --c-red_a_1a: #f9385a1a;
    --c-red_a_33: #f9385a33;
    --c-scale-bg: #b2d5ff;
    --c-white-bg: #f2f2f2;
    --c-white-border: #c4c4c4;
    --c-white: #fff;
    --c-white_a80: #ffffff80;
    --c-white_ae6: #ffffffe6;
    --c-wysiwyg-error: #fadcdc;
    --c-yellow: #f2c94c;
}

/* stylelint-enable color-no-hex */
