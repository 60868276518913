.auth {
    @mixin flexCenter;
    min-height: 100vh;
    font-size: var(--t-level3);

    .box {
        @mixin modalShadow;
        @mixin vItems;
        max-width: clamp(15ch, 35ch, 90vw);
        padding: clamp(1em, calc(1.5 * var(--p-outer)), 4.5em);
        border: 2px solid var(--c-blue2);
        border-radius: var(--b-border-radius);
        background: var(--c-white);

        .title {
            font-weight: var(--f-bold);
            font-size: var(--t-level1);
        }

        .form {
            @mixin flexVList var(--blocks-gap);
        }
    }

    .vk_auth {
        @mixin flexCenter;
        aspect-ratio: 1;
    }

    .error {
        color: var(--c-red);
    }
}
