.timer {
    @mixin flexHList 0.5ch;
    @mixin flexVCenter;
    justify-content: flex-start;
    font-weight: var(--f-normal);
    font-size: 16px;
    line-height: 20px;
    white-space: pre;

    svg {
        width: 20px;
        height: 20px;
        @mixin inlineIconStatic inherit;
    }

    span {
        width: 7ch;
        text-decoration: initial !important;
    }
}
