.answer {
    @media (--medium-until) {
        height: unset !important;
    }

    .field {
        display: inline-flex;

        @media (--medium) {
            gap: 1ch;
            align-items: center;
        }

        @media (--medium-until) {
            align-items: center;
            height: unset !important;

            &.self {
                flex-direction: column;
                align-items: flex-start;

                .text {
                    width: 100%;
                }
            }
        }

        .label {
            @media (--medium) {
                display: none;
            }
        }
    }
}
