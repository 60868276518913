:global(.uiButton),
.button {
    position: relative;
    display: flex;
    flex-shrink: 1;
    gap: 1ch;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 12px 20px;
    border: 0;
    border-radius: 40px;
    background-color: var(--c-white);
    color: var(--c-grey3);
    outline: none;
    font-weight: var(--f-bold);
    font-size: 14px;
    word-break: break-word;
    cursor: pointer;

    a,
    a:hover {
        text-decoration: none;
    }

    .contents {
        @mixin flexHList var(--items-gap);
        @mixin flexCenter;
    }

    &.fluid {
        min-width: 100%;
    }

    &.bordered {
        border: 2px solid;
    }

    &.darkText {
        color: var(--c-grey3) !important;
    }

    &.slim {
        width: 100%;
        height: 38px;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 14px;
    }

    &.responsive {
        width: max-content;
    }

    &[disabled],
    &.disabled {
        opacity: 0.5;
        cursor: initial;
        pointer-events: none;
    }
}

:global(.uiButton.blue),
.button.blue {
    &:not(.bordered) {
        background-color: var(--c-blue);
        color: var(--c-white);

        a {
            color: var(--c-white);
        }
    }

    &.bordered {
        color: var(--c-blue);
    }
}

:global(.uiButton.white),
.button.white {
    background: var(--c-white);
    color: var(--c-grey3);

    a {
        color: var(--c-grey3);
    }

    &.bordered {
        @mixin boxShadow;
        border: none;
    }

    &:hover,
    &:hover a {
        color: var(--c-blue);

        &.bordered {
            box-shadow: 0 0 2px var(--c-blue);
        }
    }
}

.loader {
    @mixin flexCenter;
    top: initial !important;
    left: initial !important;
    width: 100% !important;
    height: 100% !important;
    color: var(--c-grey3);

    svg {
        width: 3ch !important;
        height: auto;
    }
}

.borderColor_blue {
    border: none;
    @mixin boxShadow;
    padding: 8px 18px;
}
