:global(.ttDev) .matrix {
    .table {
        box-shadow: inset 0 0 0 1px var(--c-green);
    }

    .row .cell {
        box-shadow: inset 0 0 0 1px var(--c-red);
    }

    .rowHeader {
        box-shadow: inset 0 0 0 1px var(--c-green);

        .span,
        .cell {
            box-shadow: inset 0 0 0 1px var(--c-red);
        }
    }
}

.matrix {
    position: relative;
    display: grid;
    grid-template-columns: fit-content(25%) minmax(100px, 100%);
    gap: 2ch;
    width: 100%;
    min-width: 0;
    opacity: 0;

    @media (--medium-until) {
        display: flex;
        width: 100%;
    }

    &.ready {
        opacity: 1;
    }

    .bg {
        position: absolute;
        display: none;
    }

    .table {
        @media (--medium) {
            display: grid;
            align-items: start;
            width: fit-content;
            column-gap: 1ch;
        }

        @media (--medium-until) {
            display: flex;
            flex-direction: column;
            gap: 3ch;
        }
    }
}

.answers {
    display: contents;
}

.row {
    @media (--medium-until) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto !important;
    }

    @media (--medium) {
        display: contents;
    }

    &.headers {
        @media (--medium-until) {
            display: none;
        }
    }

    &.rowInvalid {
        border-radius: var(--b-border-radius);

        .cell::before {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: var(--b-border-radius);
            background: var(--c-red_a_1a);
            pointer-events: none;
            touch-action: none;
        }
    }

    .cell {
        position: relative;
        display: flex;
        height: min-content;
        min-height: 4em;
        text-align: left;

        @media (--medium-until) {
            flex-direction: column;
            width: 100%;
            height: auto !important;
            min-height: initial;
        }

        @media (--medium) {
            @mixin flexCenter;
            width: max-content;
            min-width: 100%;
        }

        p {
            margin: 0;
        }

        &.header {
            vertical-align: bottom;
            align-self: self-end;
            width: min-content;
            height: min-content;
            font-weight: var(--f-thiner);
            white-space: pre-line;

            @media (--medium) {
                /* fixes background gaps */
                align-items: flex-end;
                height: 100%;
            }
        }

        &.label {
            height: fit-content;
            text-align: left;

            @media (--medium-until) {
                font-weight: var(--f-bold);
            }

            @media (--medium) {
                display: flex;
                align-items: center;
                height: 100%;
                font-weight: var(--f-thiner);
            }
        }
    }
}

.rowHeader {
    display: grid;
    width: fit-content;

    /* force new layer */
    backface-visibility: hidden;

    @media (--medium-until) {
        display: none;
    }

    &.withScroll {
        padding-bottom: 10px;
    }

    .span {
        &.empty {
            font-size: 0;
        }
    }

    .cell {
        display: flex;
        align-items: center;
        width: fit-content;
        height: min-content;
        min-height: 4em;
        text-align: left;

        &.label {
            text-align: left;
            word-break: break-word;
        }
    }

    p {
        margin: 0;
    }
}

.rowHeaderMobile {
    @media (--medium) {
        display: none !important;
    }
}

.scrollarea {
    position: initial;
    z-index: 111;
}

.control {
    min-width: 120px;
    max-width: 300px;

    @media (--medium-until) {
        max-width: initial;
    }
}
