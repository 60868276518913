.loader {
    @mixin loader;

    &.visible {
        visibility: visible;
    }

    &.hidden {
        visibility: hidden;
    }

    &.inline {
        top: 12px;
        left: 5px;
        background: transparent;

        svg {
            width: 100%;
            height: auto;
        }
    }

    svg {
        width: 60px;
        height: 60px;
        background: transparent;
    }

    &.small svg {
        width: 32px;
        height: 32px;
    }
}
