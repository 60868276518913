.gradientNumbers {
    display: flex;
    gap: var(--blocks-gap);
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 4px;

    @media (--medium-until) {
        gap: 1ch;
    }

    &.disabled {
        pointer-events: none;
    }

    .item {
        font-weight: var(--f-bold);

        @media (--medium-until) {
            font-size: 1.25rem;
        }

        @media (--medium) {
            font-size: 2.5rem;
        }

        &.small {
            @media (--medium-until) {
                font-size: 1.15rem;
            }

            @media (--medium) {
                font-size: 1.57rem;
            }
        }

        &.itemSelected,
        &:hover {
            transform: scale(1.5);
        }

        &.itemClickable {
            cursor: pointer;
        }
    }
}
