.scrollarea {
    padding-right: 0 !important;

    @media (--medium-until) {
        width: 100%;
        /* max-width: 88vw; */
    }

    .scrollbar {
        &.vertical {
            bottom: 22px;

            @media (--medium-until) {
                top: 22px;
                bottom: 22px;
            }
        }
    }
}

.optionsWrap {
    border-radius: 0 0 8px 8px;

    @media (--medium-until) {
        border-radius: 0px !important;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 38px;
        border-radius: 0px;
        max-width: unset !important;
        width: unset !important;
        max-height: unset !important;
    }

    &.top {
        border-radius: 8px 8px 0 0;

        @media (--medium-until) {
            border-radius: 0px;
        }

        .options {
            border-radius: 8px 8px 0 0;

            @media (--medium-until) {
                border-radius: 0px !important;
            }
        }

        .scrollarea {
            .scrollbar {
                &.vertical {
                    top: 22px;
                    bottom: 0;
                }
            }
        }
    }

    .options {
        overflow: hidden;
        max-height: 310px;
        border-radius: 0 0 8px 8px;

        @media (--medium-until) {
            /* stylelint-disable */
            max-height: unset !important;
            /* stylelint-enable */
            border-radius: 0px;
        }

        & > div {
            overflow: hidden;
            &:last-child {
                border-bottom-right-radius: 8px;
                border-bottom-left-radius: 8px;
            }
            @media (--medium-until) {
                margin: 4px;
                border: 1px solid;
                &:last-child {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }

    &.active {
        @media (--medium-until) {
            width: 100%;
            max-width: 88vw;

            /* stylelint-disable */
            max-height: 90vh;
            max-height: calc(var(--vh, 1vh) * 90);
            /* stylelint-enable */
            border-radius: 8px;
        }

        .options {
            position: relative;
            z-index: 2;
            background: #fff;
        }
    }
}

.dropdown {
    position: absolute;
    z-index: 2000;
    width: 100%;
    height: fit-content;
    margin: auto;
    border-radius: 0 0 8px 8px;
    background-color: transparent;
    visibility: hidden;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.15);

    @media (--medium-until) {
        z-index: 4000;
        border-radius: 8px;
    }

    &.maxWidth {
        width: fit-content;
    }

    &.error {
        border-color: var(--c-red) !important;
    }

    &.small {
        border-radius: 0 0 18px 18px;
    }

    &.top {
        border-radius: 8px 8px 0 0;

        @media (--medium-until) {
            border-right: 8px;
        }
    }

    &.searchable {
        margin: 0;
    }

    &.active {
        display: block;
        visibility: visible;

        @media (--medium-until) {
            position: fixed !important;
            top: 10vh !important;
            bottom: 10vh !important;
            left: 5vw !important;
            z-index: 3500;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            width: 88vw !important;
            transform: none !important;

            @supports (-webkit-touch-callout: none) {
                /* IOS */
                max-width: 88vw !important;
            }

            &.searchable {
                top: 3vw !important;
                justify-content: flex-start;
            }
        }

        &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            display: block;
            border-radius: 8px;
        }
    }
}

.item {
    --hover: var(--c-blue2_33);
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-color: var(--c-blue2);
    font-weight: var(--f-normal);
    cursor: pointer;

    &.selected {
        background-color: var(--hover) !important;
    }

    @media (hover: hover) {
        &:hover {
            background-color: var(--hover) !important;
        }
    }

    &.top {
        border-color: var(--c-blue2);
        border-top: none;
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.mobileSearch {
    @mixin flexCenter;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff !important;

    &:focus-within input {
        &:focus {
            border: 2px solid var(--c-border-focus);
        }
    }

    :global(.tagsWithSearch_mobile) {
        /* margin: 8px 0; */
    }

    svg {
        cursor: pointer;
    }

    form,
    input {
        width: 100%;
        background-color: transparent;
        color: inherit;
        outline: none;
        font-size: 20px;
    }
}

.dropdown_preset_light.active {
    box-shadow: 0 0 2px 0 rgba(46, 91, 255, 0.3);
    border-radius: 12px;
    background-color: #FFF;
    width: auto !important;
}

.dropdown_preset_light.active .options {
    background-color: transparent;
    border-radius: 12px;
}

.dropdown_preset_light.active .option {
    padding-left: 0;
    padding-right: 0;
}
