.tools {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    gap: 1ch;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--c-blue);
    color: var(--c-white);
    cursor: pointer;

    &:active {
        color: var(--c-red);
    }

    svg {
        width: 90%;
    }
}
