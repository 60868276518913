.imageSlider {
    @mixin parentWindowVars;
    padding: 0;
    height: 100vh;

    .slides {
        overflow: hidden;
        min-width: calc(100% - calc(var(--vw, 1vw) * 14));
        background-color: var(--c-white);
        visibility: hidden;

        &.ready {
            visibility: visible;

            .slidesInner {
                transition: transform 0.8s;
            }
        }
    }

    .slidesInner {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .slide {
        @mixin vItems;
        @mixin flexCenter;
        width: 100%;
        height: 100%;

        .imgWrap {
            position: relative;
            max-height: 100%;
        }

        .checkbox {
            position: absolute;
            top: 3ch;
            left: 3ch;
        }

        img {
            align-self: center;
            width: 100%;
            height: auto;
            max-height: 100%;
            cursor: pointer;
        }
    }

    .close {
        z-index: 5001;
        position: absolute;
        padding: 8px;
        top: 24px;
        right: 40px;
        width: 40px;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        will-change: transform;

        &:hover {
            transform: rotate(180deg);
        }
    }

    .leftIcon,
    .rightIcon {
        width: 30px;
        height: 30px;
        border-bottom: 2px solid var(--c-blue);
        border-left: 2px solid var(--c-blue);
        cursor: pointer;
        transform: rotate(45deg);
        position: absolute;
        top: 50%;
        margin-top: -15px;
        z-index: 5001;
        left: 25px;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
    }

    .leftIcon:hover,
    .rightIcon:hover,
    .crossIcon:hover {
        opacity: 0.8;
    }

    .rightIcon {
        transform: rotate(225deg);
        left: auto;
        right: 40px;
    }
}
