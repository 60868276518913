.popper {
    z-index: 5000 !important;

    @media (--medium-until) {
        position: fixed !important;
        top: calc(50% - 334px / 2) !important;
        left: calc(50% - 288px / 2) !important;
        width: 288px;
        height: 334px;
        transform: none !important;
    }
}

.calendar {
    padding: 5px 10px;
    border: 2px solid var(--borderColor, var(--c-blue));
    border-radius: 22px;
    background-color: var(--c-white);

    @media (max-width: 540px) {
        padding: 5px 10px;
    }

    div[class*='react-datepicker__triangle'] {
        display: none !important;
    }

    div[class*='react-datepicker__header'] {
        border: 0;
        background-color: transparent !important;
    }

    div[class*='react-datepicker__current-month'] {
        padding: 5px 0 !important;
    }

    div[class*='react-datepicker__day-name'] {
        color: var(--c-grey8D) !important;
        font-weight: var(--f-bold) !important;
        font-size: 24px !important;

        @media (--component-break2) {
            font-size: 750px !important;
        }
    }

    div[class*='react-datepicker__day'] {
        width: 50px !important;
        margin: 0 !important;
        padding: 6px !important;
        color: var(--c-black) !important;
        font-weight: var(--f-bold) !important;
        font-size: 15px !important;

        @media (--component-break2) {
            padding: 2px !important;
        }
    }

    div[class*='react-datepicker__day']:hover {
        cursor: pointer !important;
    }

    div[class*='react-datepicker__day--outside-month'] {
        color: var(--c-greyC) !important;
    }

    div[class*='react-datepicker__day--disabled'] {
        opacity: 0.2 !important;
    }

    div[class*='react-datepicker__day--selected'] {
        border: 2px solid var(--borderColor, var(--c-blue)) !important;
        border-radius: 20px !important;
        background-color: var(--c-white) !important;

        @media (--component-break2) {
            border-radius: 15px !important;
        }
    }
}

.head {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.nav {
    padding: 15px 45px;
    color: var(--c-blue);
    opacity: 0.7;
    cursor: pointer;
    transition: 0.2s;

    @media (--component-break2) {
        padding: 15px 25px;
    }

    &:hover {
        opacity: 1;
    }
}

.icon {
    width: 15px;
    height: 15px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &.next {
        transform: rotate(180deg);
    }
}

.title {
    min-width: 130px;
    color: var(--c-black);
    font-weight: var(--f-bold);
    font-size: 18px;
    text-align: center;
    cursor: pointer;

    @media (--component-break2) {
        min-width: auto;
        font-size: 14px;
    }
}

.yearmonth {
    display: block !important;

    .row {
        display: flex;
        justify-content: space-around;
    }

    .cell {
        display: block;
        width: 50px;
        text-align: center;
    }
}
