.indicatorBlock {
    position: sticky;
    bottom: 0;
    z-index: 4;
    display: flex;
    flex-flow: column nowrap;
    gap: 5px;
    margin: 0 5vw;
    padding: 15px 70px 0;

    &.posTop {
        top: 0;
        bottom: initial;
        z-index: 5000;
        flex-direction: column-reverse;
        order: -1;
        padding: 0 70px 15px;
        border-radius: 0 0 var(--b-border-radius) var(--b-border-radius);
    }

    .inNumbers {
        color: var(--c-blue2);
        font-size: 16px;
        line-height: 20px;
        white-space: pre;
    }

    .top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .footerLogo {
        width: auto;
    }

    &.withoutIndicator {
        background: none;
        box-shadow: none;

        .top {
            justify-content: flex-end;
        }
    }

    .invalidQuestions {
        color: var(--c-red);
        font-size: 14px;
        line-height: 17px;
        text-align: right;
    }

    .time {
        display: flex;
        align-items: center;
        font-weight: var(--f-normal);
        font-size: 16px;
        line-height: 20px;

        svg {
            width: 20px;
            height: 20px;
            margin-right: 7px;
        }
    }

    .indicator {
        width: 100%;
        height: 4px;
        border-radius: var(--b-border-radius);
        background: var(--c-blue2_33);
    }

    .filledIndicator {
        height: 4px;
        border-radius: var(--b-border-radius);
        background: var(--c-blue2);
    }

    &.invalid {
        .inNumbers {
            color: var(--c-red) !important;
        }

        .indicator {
            background: var(--c-red_a_33) 33 !important;
        }

        .filledIndicator {
            background: var(--c-red) !important;
        }

        .time {
            color: var(--c-red) !important;
        }
    }
}
