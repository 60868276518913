/*
TODO trigger fullscreen mode only for touch screens
@media only screen and (hover: none) and (pointer: coarse){ }
*/

.multiselect {
    display: grid;
    position: relative;

    .searchInput,
    .searchInput:focus {
        width: 100px;
        border: none;
        background-color: transparent;
        color: inherit;
        outline: none;
    }

    .tagsWithSearch {
        display: flex;
        flex-wrap: wrap;
        gap: 0.25em;
        min-height: 24px;
    }

    .label {
        border: 2px solid transparent;
    }

    &.disabled {
        filter: saturate(0);
        pointer-events: none;
    }

    &:not(.disabled):focus-within .label {
        border: var(--i-focus);
    }

    &.small {
        font-weight: var(--f-normal);
    }
}

.clear {
    top: 1em;
    right: 0;
}


.tagsWithSearch_mobile {
    margin: 0;
    min-height: 34px;
}

.tagsWithSearch_mobile :global(.multi-select-tag) {
    font-size: 12px;
}

.clear {
    position: static;
}

.backButton {
    transform: rotate(180deg);
}

.mobileSearchForm {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-left: 8px;
    padding-right: 8px;
    height: 38px;
}

.mobileSearchInput, .mobileSearchInput:focus {
    outline: none;
    border: none !important;
}

.tagsWithSearch_mobile form, .tagsWithSearch_mobile input {
    width: 80px;
    font-size: 16px;
    outline: none;
    border: none !important;
}

.tagsWithSearch_mobile form {
    padding-top: 0.25em;
}

.tagsWithSearch_mobile input:focus-visible, .tagsWithSearch_mobile input:focus-within, .tagsWithSearch_mobile input:focus {
    outline: none;
    border: none !important;
}