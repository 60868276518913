.smiles {
    display: grid;
    gap: 3vw;
    justify-items: center;
    width: 100%;
    min-width: 0;

    @media (--medium) {
        width: fit-content;
    }

    .smileText {
        text-align: center;

        @media (--medium-until) {
            word-break: break-all;
        }

        p {
            margin: 0;
            padding: 0;
        }
    }

    .smile {
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        flex-direction: column;
        gap: 1ch;
        align-items: center;
        width: min-content;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        @media (--medium-until) {
            width: 100% !important;
            height: 100% !important;
        }

        @media (hover: hover) {
            &:hover {
                svg {
                    opacity: 0.6;

                    @media (hover: none) {
                        opacity: 1;
                    }
                }
            }
        }

        &.notSelected {
            svg {
                opacity: 0.6;
            }
        }

        &.selected {
            svg {
                opacity: 1;
            }
        }
    }
}

.cantRateCheckbox {
    place-content: start !important;
    margin-top: 18px;
}

.container {
    display: flex;
    flex-direction: column;
}

.customImage {
    @media (hover: hover) {
        &:hover {
            opacity: 0.6 !important;
        }
    }
}

.svgIcon {
    display: flex;
}

.iconSize1,
.iconSize1 svg {
    width: 49px;
    height: 49px;
}

.iconSize2,
.iconSize2 svg {
    width: 77px;
    height: 77px;
}
