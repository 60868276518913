.item {
    @mixin flexCenter;
    flex-grow: 1;
    height: 2em;
    max-height: 2em;
    font-weight: var(--f-bold);
    cursor: pointer;

    @media (--medium-until) {
        min-width: 2ch;
        text-align: center;
    }

    @media (--medium) {
        &.sm {
            font-size: 1.5rem;
        }

        &.lg {
            font-size: 2.65rem;
        }
    }

    &.selected,
    &:hover {
        span {
            display: inline-block;
            transform: scale(1.5);
        }
    }
}

.cantRateCheckbox {
    place-content: start;
    margin-top: 18px;
}

.container {
    display: flex;
    flex: 1;
    flex-direction: column;
}
