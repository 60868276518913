.proPlus {
    display: inline-block;
    color: var(--c-blue);
    font-weight: var(--f-bolder);
    cursor: pointer;
}

.order {
    display: inline;
    margin-right: 0.5em;
    font-weight: var(--f-bold);

    &.invalid {
        color: var(--c-red) !important;
    }
}

.answer {
    font-weight: var(--f-normal);
    font-size: 14px;
    margin: 10px 0;
}

.answerIcon {
    @mixin flexVCenter;

    & > * {
        width: 2ch;
    }

    .valid {
        color: var(--c-blue);
    }

    .invalid {
        color: var(--c-red);
    }
}
