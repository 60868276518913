.tag {
    @mixin flexHList var(--items-gap);
    margin: 0;
    padding: 0em 0.75em;
    border-radius: 6px;
    background: var(--c-white);
    &:first-child {
        margin-left: 0;
    }
}

.tagText {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
}
