.pointsResults {
    @mixin flexHList 0.5ch;
    @mixin flexCenter;
    padding: 0.75em 1.5em;
    border-radius: var(--b-border-radius);
    background: var(--c-border);
    color: var(--c-grey3);
    font-weight: var(--f-bold);
    font-size: var(--t-level5);

    .pointsLabel p {
        display: inline;
    }

    svg {
        @mixin inlineIconStatic var(--c-grey3), 1.5em;
    }
}
