.list {
    display: flex;
    flex-direction: column;
    gap: 2ch;
    width: 100%;

    @media (--medium) {
        display: grid;
    }

    .answer {
        display: grid;
        grid-template-rows: 1fr;
        gap: 2ch;
        align-items: center;

        @media (--medium) {
            display: contents;
        }

        @media (--medium-until) {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: unset !important;
        }

        .label {
            display: flex;
            align-items: center;
            font-weight: var(--f-thiner);
            overflow-wrap: anywhere;

            @media (--medium-until) {
                align-self: flex-start;
            }

            p {
                margin: 0;
            }
        }

        .fields {
            display: contents;
            gap: 1ch;

            @media (--medium-until) {
                display: grid;
                grid-template-columns: minmax(80px, 1fr) 5fr;
                width: 100%;
                min-width: 0;
            }

            @media (--small-until) {
                grid-template-columns: 1fr;
                width: 100%;
                min-width: 0;
            }

            .field {
                display: flex;

                @media (--medium) {
                    width: 100%;
                }

                @media (--medium-until) {
                    display: contents;
                }

                &.code {
                    .control {
                        text-align: center;
                    }
                }
            }
        }
    }
}
