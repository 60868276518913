.numbers {
    display: flex;
    align-items: center;
    gap: 1ch;
    width: 100%;

    @media (--medium-until) {
        gap: 0.5ch;
    }
}

.number {
    height: 4ch;
    min-width: 4ch;
    max-width: 16ch;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 1px solid blue;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }

    @media (--medium-until) {
        min-width: 1.75ch;
        min-height: 4ch;
        height: 4ch;
        font-size: 16px;

        &:hover {
            opacity: 1;
        }
    }
}

.selectedNumber {
    opacity: 1;
    border: 2.5px solid blue;
    font-weight: bold;
}
