.number {
    @media (--medium-until) {
        width: 100%;
    }

    .control {
        max-width: 570px;

        @media (--medium-until) {
            width: 100% !important;
            max-width: initial;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
        }
    }
}
