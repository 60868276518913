.answer {
    .field {
        @media (--medium-until) {
            display: flex;
            flex-direction: column;
        }

        @media (--medium) {
            display: flex;
            flex-grow: 0;
            flex-shrink: 1;
            width: 100%;
            max-width: 100%;
        }

        .name {
            text-align: left;

            @media (--medium-only) {
                max-width: 100px;
            }

            @media (--medium) {
                display: none;
            }
        }

        .text {
            @media (--medium) {
                width: 100%;
            }
        }
    }
}
