/*
TODO trigger fullscreen mode only for touch screens
@media only screen and (hover: none) and (pointer: coarse){ }
*/

.select {
    display: grid;
    position: relative;

    .searchInput,
    .searchInput:focus {
        width: calc(100% - 2em);
        border: none;
        background-color: transparent;
        color: inherit;
        outline: none;
    }

    .label {
        border: 2px solid transparent;
    }

    &.disabled {
        filter: saturate(0);
        pointer-events: none;
    }

    &:not(.disabled):focus-within .label {
        border: var(--i-focus);
    }

    &.small {
        font-weight: var(--f-normal);
    }
}

.clear {
    position: static;
}

.backButton {
    transform: rotate(180deg);
}

.mobileSearchForm {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-left: 8px;
    padding-right: 8px;
    height: 38px;
}

.mobileSearchInput, .mobileSearchInput:focus {
    outline: none;
    border: none !important;
}