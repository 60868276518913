.note {
    font-weight: var(--f-normal);
    font-size: var(--t-level2);

    img {
        width: 100%;
        height: auto;
        border-radius: var(--b-border-radius-2);
    }
}
