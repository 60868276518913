.root {
    @mixin flexVList 0;
    width: 100%;
}

.rank {
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
        margin: 0;
        word-break: break-all;
    }
}

.variant {
    @mixin flexHList var(--items-gap);
    @mixin flexVCenter;
    width: 100%;
    max-width: max-content;
    height: auto;
    touch-action: auto !important;

    &.variantMobile {
        max-width: unset;
    }

    .order {
        white-space: nowrap;
    }

    .text {
        overflow: hidden;
        width: 100%;
        user-select: none;
    }

    .textMobile {
        flex-grow: 1;
    }
}

.current {
    visibility: hidden;
}

.draggable {
    @mixin popupShadow;
    width: fit-content;
    width: 100%;
    padding: 0.5em;
    border-radius: 8px;
    background-color: var(--c-white) !important;
    opacity: 0.9 !important;
    touch-action: none;
}
