.footer {
    position: relative;
    z-index: 5;
    width: 100%;

    .footerWrap {
        width: 100%;
        max-width: var(--content-max-width);
        margin: 11px auto;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
        align-items: center;
    }

    .copy {
        display: inline-flex;
        gap: 1ch;
        align-items: center;
        color: var(--c-grey3);
        font-weight: var(--f-normal);
        font-size: var(--t-level4);
        line-height: 1;
        white-space: nowrap;

        &:visited {
            color: var(--c-grey3);
        }

        .logo {
            width: 68px;
        }
    }
}
