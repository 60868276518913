.slider {
    --top-offset: -2.75em;
    --side-offset: 0.5em;
    @mixin hItems;
    @mixin flexVCenter;
    width: 100%;
    font-size: 0.85em;

    .label::after {
        content: '';
        position: absolute;
        bottom: -1.2em;
        display: block;
        width: var(--border-width);
        height: 1em;
        margin: auto;
        border-radius: 1.5em;
        background-color: var(--v-bg-color, currentColor);
        opacity: 0.2;
    }
}

.range {
    flex-grow: 1;
    margin: 0 var(--side-offset);
    padding-top: 1.5em;
    user-select: none;

    &.disabled {
        .control .bar {
            background: var(--c-grey3) !important;
            opacity: 0.2 !important;
        }

        .label::after {
            background: var(--c-grey3) !important;
            opacity: 0.2 !important;
        }
    }

    .control {
        * {
            outline: none !important;
        }

        .track {
            top: 0;
            bottom: 0;
            margin: auto 0.4em;

            &:nth-child(1) {
                height: 0.5em;
                border-radius: 0.75em;
                background-image: var(--track-bg-image);
                background-size: var(--range-width);
                background-color: var(--c-blue2);
            }

            &:nth-child(2) {
                right: 0 !important;
                height: var(--border-width);
                border-radius: 1.5em;
                background: var(--c-blue2);
                opacity: 0.2;
            }
        }

        .handler {
            width: 1em;

            &::after {
                @mixin boxShadow;
                content: '';
                position: absolute;
                z-index: 30;
                width: 1em;
                height: 1em;
                border-radius: 100%;
                background-color: var(--c-white);
                cursor: pointer;
                transform: translateY(-50%);
                border: 1px solid var(--v-bg-color, transparent);
            }

            .label {
                position: absolute;
                top: var(--top-offset);
                z-index: 20;
                width: 100%;
                font-weight: var(--f-bolder);
                text-align: center;

                &::after {
                    left: calc(50% - var(--border-width) / 2);
                }
            }
        }
    }
}

.labels {
    z-index: 10;
    display: flex;
    justify-content: space-between;
    margin: 0 var(--side-offset);

    .label {
        position: relative;

        &::after {
            bottom: 0;
        }

        .text {
            position: absolute;
            top: var(--top-offset);
            font-weight: var(--f-bolder);
            transform: translateX(-50%);
        }
    }
}

.value .control {
    margin-bottom: 0.85em;
    padding-right: 0.2em;
    padding-left: 0.2em;
    text-align: center;
}

.sliderLabelsBottom .range .control {
    padding: 12px 0;
}

.labelsNew {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 4px;
    margin-top: 4px;
}

.sliderLabelsBottom .label {
    display: none;
}

.labelsNewSelected {
    font-weight: bold;
    transform: scale(1.5);
}
