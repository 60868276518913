.password {
    @mixin bottomLine;
    @mixin flexCenter;

    input {
        flex-grow: 1;
        width: 100%;
        border: none;
        outline: none;
        font-weight: var(--f-normal);
        resize: none;
    }

    &:disabled {
        background-color: var(--c-greyF4);
    }

    .icon {
        @mixin flexCenter;
        display: inline-flex;
        justify-self: flex-end;
        padding: 0.5em;
        opacity: 0.5;
        cursor: pointer;

        svg {
            @mixin inlineIconStatic var(--c-greyC);
        }
    }
}
