.title {
    position: absolute;
    line-height: 1.5em;
    text-align: center;
    word-break: break-word;

    @media (--medium-until) {
        position: static !important;
        width: auto !important;
        height: auto !important;
    }

    &.inHead {
        @media (--medium-until) {
            &:empty {
                min-height: var(--mobile-height);
            }
        }
    }

    &.left {
        text-align: left;
    }

    &.center {
        text-align: center;
    }

    &.right {
        text-align: right;
    }
}

.container {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    margin: initial;

    @media (--medium-until) {
        position: static;
        z-index: -1;
        order: 2;
    }
}
