.modal {
    @mixin parentWindowVars;

    @media (--medium-until) {
        /* stylelint-disable */
        padding: calc(var(--vw) * 5) calc(var(--vw) * 5);
        padding: calc(var(--vh, 1vh) * 5) 5vw;
        /* stylelint-enable */
    }

    .close {
        position: fixed;
        top: calc(var(--vw) * 3);
        right: calc(var(--vw) * 3);
        width: 3em;
        height: 3em;
    }

    .content {
        @mixin modalShadow;
        width: calc(var(--vw) * 75);
        height: calc(var(--vh) * 65);
        margin: auto;
        padding: calc(var(--vh) * 5) calc(var(--vw) * 5);
        border-radius: var(--b-border-radius);
        background: var(--c-white);

        @media (--medium-until) {
            position: absolute;
            /* stylelint-disable */
            top: calc(var(--vh) * 5);
            top: calc(var(--vh, 1vh) * 5);
            /* stylelint-enable */
            left: calc(var(--vw) * 5);
            width: calc(var(--vw) * 90);
            margin: 0;
            /* stylelint-disable */
            height: calc(var(--vh) * 5);
            height: calc(var(--vh, 1vh) * 90);
            /* stylelint-enable */
        }
    }
}
