:global {
    p {
        margin: 0;
    }

    html {
        /* stylelint-disable */
        -webkit-text-size-adjust: none !important;
        /* stylelint-enable */
    }

    .embedded,
    .embedded body {
        scrollbar-color: var(--tt-elementColor) var(--c-white);
        scrollbar-width: thin;
        background-color: transparent;

        &::-webkit-scrollbar {
            width: 2px;
        }

        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar-corner,
        &::-webkit-resizer,
        &::-webkit-scrollbar-track-piece,
        &::-webkit-scrollbar-button {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-color: var(--tt-elementColor);
        }

        &::-webkit-scrollbar-track-piece:end {
            margin-bottom: var(--scroll-offset);
        }

        &::-webkit-scrollbar-track-piece:start {
            margin-top: var(--scroll-offset);
        }
    }

    .embedded .surveyInner > .content {
        flex: none;
        padding: 25px 35px !important;

        @media (--medium-until) {
            padding: 15px !important;
        }
    }

    .embedded .survey .content .question {
        padding: 0;
    }

    .embedded .surveyInner > .headerWrap {
        padding: 20px 30px;
    }

    .embedded .survey .content .section {
        flex-grow: unset;
    }

    .embedded .survey .surveyInner {
        min-height: auto;
        padding: 10px 0;
    }

    .embedded .surveyInner textarea,
    .embedded .surveyInner input {
        font-size: 16px !important;
    }
}

.survey {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* stylelint-disable */
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    /* stylelint-enable */

    &.embedded {
        min-height: 0;
    }

    .welcomeContainer,
    .content {
        @media (--medium-until) {
            padding-right: 10px !important;
            padding-left: 10px !important;
        }
    }

    .content {
        display: flex;
        flex-grow: 1;
        flex-flow: column;

        &.startText {
            flex-grow: initial;
        }
    }

    .bg {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        margin: 0;
        padding: 0;
        bottom: 0;
    }

    .surveyInner {
        z-index: 2;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        width: 100%;
        max-width: var(--content-max-width);
        min-height: 100vh;
        /* stylelint-disable */
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
        /* stylelint-enable */
    }

    .surveyInnerWithFooter {
        min-height: calc(100vh - 40px);
        min-height: calc((var(--vh, 1vh) * 100) - 40px);
    }

    .container {
        display: flex;
        flex: 1 1 100%;
        flex-flow: column;
        width: 100%;
        max-width: var(--content-max-width);
        margin: 0 auto;
    }
}

.noSelection {
    user-select: none;
}

.startText {
    flex-grow: 1;
}

.copyBtn {
    position: fixed;
    top: 2vw;
    right: 2vw;
    z-index: 10000;
}

.embeddedContentWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
