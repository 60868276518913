@keyframes pulsate {
    0% {
        opacity: 0;
        transform: scale(0.1, 0.1);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(1.2, 1.2);
    }
}

.record {
    @mixin gridCenter;
    position: relative;

    .inner {
        position: absolute;
        width: 1.25em;
        height: 1.25em;
        border-radius: 50%;
        background-color: var(--c-error);
    }

    .outer {
        position: absolute;
        width: 2em;
        height: 2em;
        border: 0.25em solid var(--c-error);
        border-radius: 50%;
        opacity: 0;
        animation: pulsate 1s ease-out;
        animation-iteration-count: infinite;
    }
}
