.list {
    --icon-offset: 1.5rem;
    display: grid;
    justify-content: space-between;

    @media (--medium-until) {
        grid-template-columns: 1fr !important;
    }

    @media (--medium) {
        &.col1 {
            .answer {
                .text {
                    font-size: 1em;
                }
            }
        }

        &.col2 {
            .answer {
                .text {
                    font-size: 0.85em;
                }
            }
        }

        &.col3 {
            .answer {
                .text {
                    font-size: 0.75em;
                }
            }
        }
    }

    .zoomIcon {
        position: absolute;
        right: var(--icon-offset);
        bottom: var(--icon-offset);
        z-index: 10;
        max-width: 2rem;
        max-height: 2rem;
    }

    & p {
        margin: 0;
    }

    .answer {
        @mixin flexVList 1rem;
        position: relative;
        justify-content: space-between;
        padding: 2px;

        @media (--medium-until) {
            width: auto !important;
        }

        .image {
            position: relative;
            display: flex;
            overflow: hidden;
            box-sizing: border-box;
            border-radius: var(--b-border-radius-2);
            box-shadow: 0 0 0 1px var(--border-color);
            cursor: pointer;
            -webkit-tap-highlight-color: var(--c-disale-tap);

            &.empty {
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;

                svg {
                    width: 33%;
                    height: auto;
                    margin: 2ch;
                    color: var(--border-color);
                }
            }

            img {
                position: relative;
                z-index: 1;
                object-fit: cover;
                width: 100%;
                height: auto;
                pointer-events: none;
            }
        }

        .checkbox {
            position: absolute;
            top: var(--icon-offset);
            left: var(--icon-offset);
            z-index: 1;
        }

        .text {
            font-weight: var(--f-thiner);
            word-break: break-word;
        }
    }
}
