.bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
}

.dropdownBg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    @media (--medium) {
        background-color: transparent !important;
    }

    @media (--medium-until) {
        background-color: var(--c-blue2_a_73);
    }
}
