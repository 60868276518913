.dropdown {
    width: 400px;
    min-width: 400px;

    @media (--medium-until) {
        width: 100%;
        min-width: 100px;
    }

    .control {
        min-width: 180px;
        max-width: 570px;
    }
}
