.scrollarea {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    max-width: 100%;
    height: 100%;
    contain: content;

    @media print {
        height: auto !important;
    }

    &.hideScrollBar {
        &.vertical,
        &.horizontal,
        .scrollbar {
            display: none !important;
        }
    }

    &.grab {
        .scrollbar .thumb {
            opacity: 1 !important;
        }
    }

    &.unobtrusive {
        .scrollbar .thumb {
            opacity: 0.2;
        }

        &:hover .scrollbar .thumb {
            opacity: 1;
        }
    }

    &.vertical {
        .scrollbar {
            &.horizontal {
                right: 20px;
            }
        }
    }

    &.horizontal {
        .scrollbar {
            &.vertical {
                bottom: 20px;
            }
        }
    }

    &.horizontal.withScroll .content {
        padding-bottom: 10px;
    }

    .content {
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        height: 100%;
        padding: 2px;

        @media print {
            overflow: visible;
        }

        &.grabMove {
            cursor: grabbing !important;
            user-select: none;

            > * {
                cursor: grabbing !important;
                user-select: none;
            }
        }
    }

    .scrollbar {
        position: absolute;
        z-index: 1000;
        background-color: var(--c-greyF4);
        user-select: none;
        transform: translate3d(0, 0, 0);

        @media print {
            display: none !important;
        }

        &.horizontal {
            right: 0;
            bottom: 0;
            left: 0;
            height: 3px;
            border-radius: 3px;

            .thumb {
                width: 100px;
                height: 100%;
                border-radius: 3px;
            }
        }

        &.vertical {
            top: 0;
            right: 0;
            bottom: 0;
            width: 3px;
            border-radius: 3px;

            &.scrollbarBig {
                width: 6px;
            }

            .thumb {
                width: 100%;
                height: 100px;
                border-radius: 3px;
            }
        }

        .thumb {
            position: relative;
            border-radius: 3px;
            background-color: var(--c-blue);
            cursor: pointer;

            @media print {
                display: none;
            }
        }
    }
}
