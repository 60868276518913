.answer {
    .field {
        @media (--medium-only) {
            display: flex;
            align-items: center;
        }

        @media (--component-break1) {
            height: unset !important;
        }

        .name {
            text-align: left;
        }

        .text {
            flex-grow: 1;
        }
    }
}
