@define-mixin maxWidth $max: var(--max-width) {
    max-width: $max;
}

@define-mixin barGradient {
    background: linear-gradient(226.44deg, var(--c-blue-middle) 26.09%, var(--c-blue-end) 111.58%);
}

@define-mixin headerGradient {
    background: linear-gradient(25.27deg, var(--c-blue-middle) 57.96%, var(--c-blue-end) 125.07%);
}

@define-mixin boxShadow {
    box-shadow: 0 0 2px var(--c-blue-a4d);
}

@define-mixin modalShadow {
    box-shadow: 0 40px 64px var(--c-blue6_a_3d);
}

@define-mixin bottomLine {
    box-shadow: 0 2px 0 var(--c-blue2);
}

@define-mixin bottomLine2 {
    border-bottom: 1px solid var(--c-blue2_33);
}

@define-mixin popupShadow $color: var(--c-blue_1a) {
    box-shadow: 0 4px 10px $color;
}

@define-mixin cardShadow $color: var(--c-blue6_a_66) {
    box-shadow: 0 4px 10px var(--c-blue6_a_66);
}

@define-mixin resultShadow $color: var(--c-blue6_a_66) {
    box-shadow: 0 4px 25px var(--c-blue6_a_66);
}

@define-mixin widgetBorder {
    @mixin boxShadow;
    border-radius: var(--b-border-radius);
}

@define-mixin dottedBorder {
    border: 0.15em dotted var(--c-blue);
}

@define-mixin dottedButton {
    @mixin dottedBorder;
    border-radius: 8px;
}

@define-mixin parentWindowVars {
    --vh: calc(var(--parent-window-height, 100vh) * 0.01);
    --vw: calc(var(--parent-window-width, 100vw) * 0.01);
    position: fixed;
    top: var(--popup-offset-y, 0);
    right: 0;
    left: 0;
    z-index: 5000;
    display: flex;
    place-content: center;
    width: 100vw;
    height: var(--parent-window-height, 100vh);
    padding: calc(var(--vh) * 10) calc(var(--vw) * 7);
    background: var(--c-white_ae6);
    transform: translate3d(0, 0, 0);
}

@define-mixin loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    flex-flow: row nowrap;
    place-content: center;
    align-items: center;
    background: var(--c-white_a80);
    color: var(--c-blue2);
}

@define-mixin popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    outline: none !important;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: block;
        background: #D9D9D9;
        transition: opacity 0.3s linear;
        opacity: 0.8;
    }

    &.opened {
        .popup {
            @media (--medium-until) {
                transform: translateY(0);
            }
        }
    }

    &:not(.opened) {
        &::before {
            opacity: 0;
        }

        .popup {
            @media (--medium-until) {
                transform: translateY(190%);
            }

            @media (--medium) {
                opacity: 0;
            }
        }
    }

    &.closing {
        &::before {
            opacity: 0;
        }

        .popup {
            @media (--medium-until) {
                transform: translateY(190%);
            }

            @media (--medium) {
                opacity: 0;
            }
        }
    }

    .container {
        display: flex;
        place-content: center;
        width: 100%;
        height: 100%;
        outline: none !important;

        @media (--medium) {
            overflow: auto;
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    .popup {
        position: relative;
        z-index: 2;
        width: 100%;
        margin-top: auto;
        margin-bottom: auto;

        @media (--medium-until) {
            transition: transform 0.3s linear;
        }

        @media (--medium) {
            transition: opacity 0.3s ease-in-out;
        }
    }
}

@define-mixin flexCenter {
    display: flex;
    place-content: center;
    align-items: center;
}

@define-mixin flexVCenter {
    display: flex;
    align-content: center;
    align-items: center;
}

@define-mixin flexVList $gap {
    display: flex;
    flex-direction: column;
    gap: $gap;
}

@define-mixin flexHList $gap {
    display: flex;
    flex-direction: row;
    gap: $gap;
}

@define-mixin hItems {
    @mixin flexHList var(--items-gap);
    @mixin flexVCenter;
}

@define-mixin hList {
    @mixin flexHList var(--list-gap);
    @mixin flexVCenter;
}

@define-mixin vItems {
    @mixin flexVList var(--list-gap);
}

@define-mixin inlineIconStatic $color: var(--c-blue), $size: 1.25em, $maxHeight: 1.25em {
    flex-shrink: 0;
    width: $size;
    height: auto;
    max-height: $maxHeight;
    color: $color;
}

@define-mixin inlineIcon $color: var(--c-blue), $hoverColor: var(--c-white), $size: 16px, $maxHeight: 16px {
    @mixin inlineIconStatic $color, $size, $maxHeight;
    cursor: pointer;

    &:hover {
        color: $hoverColor;
    }
}

@define-mixin formGrid {
    display: grid;
    row-gap: var(--list-gap);
    column-gap: var(--list-gap);
}

@define-mixin gradientLine {
    width: 2px;
    border-radius: 2px;
    background: linear-gradient(268.05deg, var(--c-blue-middle) 43.27%, var(--c-blue-end) 95.95%);
}

@define-mixin verticalLine {
    &::before {
        @mixin gradientLine;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -2px;
    }
}

@define-mixin gridCenter {
    display: grid;
    align-items: center;
    justify-items: center;

    & > * {
        grid-row: 1/-1;
        grid-column: 1 /-1;
    }
}

@define-mixin link {
    color: var(--c-blue);
    cursor: pointer;

    &:hover {
        color: var(--c-grey3);
    }
}

@define-mixin divider {
    width: 0.125em;
    height: 2em;
    border-radius: 0.125em;
    background: linear-gradient(217.34deg, var(--c-blue-middle) 43.27%, var(--c-blue-end) 95.95%);
}

@define-mixin disabled {
    filter: grayscale(100%);
    pointer-events: none;
}

@define-mixin listWidgetBorder {
    border: 2px solid var(--c-border);
}

@define-mixin widgetShadow {
    box-shadow: 0 4px 25px var(--c-blue6_a_66);
}

@define-mixin closeBtnBase {
    color: var(--c-grey3);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    will-change: rotate;

    &:hover {
        color: var(--c-blue);
        transform: rotate(180deg);
    }
}

@define-mixin closeBtn {
    @mixin closeBtnBase;
    position: absolute;
    top: clamp(1ch, 1vw, 1vw);
    right: clamp(1ch, 1vw, 1vw);
    width: clamp(10px, var(--t-level1), var(--t-level1));
    height: clamp(10px, var(--t-level1), var(--t-level1));
    padding: 0.35em;
}
