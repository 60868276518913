.list {
    @mixin flexVList var(--list-gap);

    @media (--medium-until) {
        width: 100%;
    }

    .control {
        min-width: 20ch;
    }

    .answer {
        @mixin flexVCenter;
        @mixin flexHList var(--items-gap);

        @media (--medium-until) {
            @mixin flexVList var(--list-gap);
            align-items: flex-start;
            width: 100%;
            height: unset !important;
        }

        .label {
            font-weight: var(--f-thiner);

            @media (--medium) {
                min-width: 95px;
            }
        }

        .field {
            flex-grow: 1;
            min-height: 2em;

            @media (--medium) {
                max-width: 450px;
            }

            @media (--medium-until) {
                width: 100%;
            }
        }
    }
}
