.closeBtnBase {
    z-index: 900;
    color: var(--c-blue);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    will-change: rotate;

    &:hover {
        color: var(--c-blue);
        transform: rotate(180deg);
    }
}

.modalCloseBtn {
    position: absolute;
    top: clamp(1ch, 1vw, 1vw);
    right: clamp(1ch, 1vw, 1vw);
    width: clamp(10px, var(--t-level1), var(--t-level1));
    height: clamp(10px, var(--t-level1), var(--t-level1));
    padding: 0.35em;

    &:hover .icon {
        color: var(--c-blue);
        transform: rotate(180deg);
    }

    .icon {
        transition: 0.3s ease-in-out;
        will-change: rotate;
    }
}

.inlineCloseBtn {
    width: 0.75em;
}

.closeBtn {
    position: absolute;
    top: clamp(1ch, 1vw, 1vw);
    right: clamp(1ch, 1vw, 1vw);
    width: clamp(10px, var(--t-level1), var(--t-level1));
    height: clamp(10px, var(--t-level1), var(--t-level1));
    padding: 0.35em;
}

.closeRedBtn {
    position: absolute;
    width: 0.75em;
    height: 0.75em;
    color: var(--c-grey3);
    cursor: pointer;

    &:hover {
        color: var(--c-red);
    }
}
