.label {
    --v-border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    padding: 0.35em 2em 0.35em 1.4em;
    height: 34px;
    border-radius: var(--v-border-radius);
    background-color: var(--c-border);
    outline: none;
    font-weight: var(--f-normal);
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &.rootSearchable {
        padding-left: 0;
    }

    &.rootSearchable:focus-within {
        padding-left: 2em;
    }

    @media (--medium-until) {
        padding-left: 0;
    }

    .arrow {
        position: absolute;
        top: 0;
        right: 8px;
        bottom: 0;
        width: 16px;
        height: 16px;
        margin: auto;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &:hover .clear {
        visibility: visible;
    }

    .clear {
        right: 32px;
    }

    &.placeholder {
        color: var(--c-grey8D);
    }

    &.disabled {
        background-color: var(--c-greyF4);
        color: var(--c-greyF4);
        opacity: 0.5;
        pointer-events: none;
    }

    &.active {
        z-index: 101;
        border-radius: var(--v-border-radius) var(--v-border-radius) 0 0;

        &::after {
            transform: rotate(180deg);
        }
    }

    &.top {
        &.active {
            border-radius: 0 0 var(--v-border-radius) var(--v-border-radius);
        }
    }

    &.error {
        color: var(--c-red) !important;
    }

    &.clearable {
        .title {
            margin-right: 1em;
        }
    }

    .search {
        @mixin flexCenter;
        position: absolute;
        top: 1px;
        left: 6px;
        height: 100%;
        visibility: hidden;

        svg {
            width: 1.85em;
        }
    }

    &:focus-within .search {
        visibility: visible;
        @media (--medium-until) {
            visibility: hidden;
        }
    }

    .title {
        overflow: hidden;
        width: 100%;
        line-height: 1.5;
        text-overflow: ellipsis;

        &:empty::before {
            content: '\00a0'; /* gives height */
        }

        &.searchable {
            margin-left: 1em;
            @media (--medium-until) {
                margin-left: 8px;
            }
        }

        form {
            width: 100%;
        }
    }
}

.label.label_preset_light {
    background-color: transparent;
    border: none !important;
    padding: 5px 22px 5px 5px;
    width: auto !important;

    .arrow {
        right: 0;
        margin-top: auto;
        margin-bottom: auto;
    }
}
