.list {
    display: grid;
    grid-template-columns: fit-content(50%) minmax(100px, auto);
    gap: 2ch;
    align-items: center;
    width: 100%;

    @media (--medium-until) {
        display: flex;
        flex-direction: column;
    }

    .answer {
        display: contents;

        @media (--medium-until) {
            @mixin flexVList var(--list-gap);
            align-items: flex-start;
            width: 100%;
            height: unset !important;
        }

        .label {
            font-weight: var(--f-thiner);
            overflow-wrap: anywhere;

            @media (--medium) {
                min-width: 0;
            }
        }

        .field {
            flex-grow: 1;

            @media (--medium) {
                max-width: 450px;
            }

            @media (--medium-until) {
                width: 100%;
            }
        }
    }
}
