.modal {
    @mixin parentWindowVars;

    @media (--medium-until) {
        /* stylelint-disable */
        padding: calc(var(--vw) * 5) calc(var(--vw) * 5);
        padding: calc(var(--vh, 1vh) * 5) 5vw;
        /* stylelint-enable */
    }

    .close {
        @mixin closeBtnBase;
        position: absolute;
        top: calc(var(--vw) * 5);
        right: calc(var(--vw) * 5);
        width: 24px;

        @media (--medium-until) {
            top: calc(var(--vw) * 6);
            right: calc(var(--vw) * 8);
            width: 30px;
            height: 30px;
        }
    }

    .content {
        @mixin modalShadow;
        width: calc(var(--vw) * 75);
        height: calc(var(--vh) * 65);
        margin: auto;
        padding: calc(var(--vh) * 5) calc(var(--vw) * 5);
        border-radius: 25px;
        background: var(--c-white);

        @media (--medium-until) {
            position: absolute;
            /* stylelint-disable */
            top: calc(var(--vh) * 5);
            top: calc(var(--vh, 1vh) * 5);
            /* stylelint-enable */
            left: calc(var(--vw) * 5);
            width: calc(var(--vw) * 90);
            margin: 0;
            /* stylelint-disable */
            height: calc(var(--vh) * 5);
            height: calc(var(--vh, 1vh) * 90);
            /* stylelint-enable */
        }
    }
}
