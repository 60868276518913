.testResultsWrapper {
    @mixin flexVList 0;
    @mixin flexCenter;

    & p {
        margin: 0;
    }

    .questionsWrap {
        @mixin flexVList 0;
        width: 100%;
    }

    .testResults {
        @mixin widgetShadow;
        @mixin flexVList 0;
        width: 50vw;
        max-width: 802px;
        margin: 20px 0;
        border-radius: 25px;
        background: var(--c-white);

        @media (--medium-until) {
            width: 90vw;
        }

        .header {
            @mixin flexVCenter;
            justify-content: center;
            height: 45px;
            padding: 0 calc(1.25 * 25px);
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            color: var(--c-white);
            font-weight: var(--f-bold);

            p {
                display: inline;
            }
        }

        .content {
            @mixin flexVList var(--blocks-gap);
            @mixin flexCenter;
            gap: 0;
            padding: 65px 40px;

            .descr {
                margin-top: 15px;
                margin-bottom: 25px;
                color: var(--c-grey3);
                font-weight: var(--f-thiner);
            }

            .result {
                color: var(--c-grey3);
                font-weight: var(--f-bold);
                font-size: 36px;
                line-height: 1;
                line-height: 44px;
                text-align: center;

                p {
                    display: inline;
                }
            }

            .body {
                @mixin vItems;
                @mixin flexCenter;
                flex-direction: row;
            }
        }
    }
}
