.handler {
    display: flex;
    align-items: center;
    cursor: grabbing;
    position: relative;

    &.big {
        width: 40px;
        height: 40px;

        &.withListeners {
            .handle {
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0px;
                left: 0px;
                right: -4px;
                bottom: -4px;

                svg {
                    position: absolute;
                    left: unset;
                    right: 8px;
                }
            }
        }
        svg {
            transform: scale(2);
        }
    }
    .handle {
        display: flex;
    }

    .extra {
        display: flex;
        margin-left: var(--items-gap);
        font-weight: var(--f-bold);
    }

    &.useHandle:hover .drag {
        transform: scale(1.1);
    }

    &.withListeners {
        .handle {
            position: relative;
        }

        .handle {
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -4px;
            left: 0px;
            right: -4px;
            bottom: -4px;

            svg {
                position: absolute;
                left: 8px;
            }
        }

        .extra {
            margin-left: 30px;
        }
    }
}

.drag {
    opacity: 0.4;
}

.sortableContainer {
    user-select: none !important;
    touch-action: none !important;
    -webkit-touch-callout: none !important;
}
