.question {
    display: flex;
    flex-flow: column nowrap;
    padding: 0.5em 1em;
    border: 2px solid transparent;

    &:last-child {
        margin-bottom: 0 !important;
    }

    .commentInvalid {
        color: var(--c-red);
        font-weight: var(--f-thiner);
    }

    &.withError {
        border-color: var(--c-red);
        border-radius: 1em;

        .commentRequired::after svg circle {
            fill: var(--c-red) !important;
        }

        .header {
            .title .requiredTitle {
                svg circle {
                    fill: var(--c-red) !important;
                }
            }
        }
    }

    &.noSelection {
        user-select: none;
    }

    .requiredText {
        color: var(--c-red);
        font-weight: var(--f-thiner);
    }

    .header {
        position: relative;
        display: inline;
        width: max-content;
        max-width: 80vw;

        &.start {
            justify-content: flex-start;
        }

        &.end {
            justify-content: flex-end;
        }

        &.center {
            justify-content: center;
        }

        .number {
            margin-right: 1em;
            color: var(--c-blue);
        }

        .title {
            position: relative;
            display: inline;

            .inner {
                word-break: break-word;
                overflow-wrap: break-word;
            }

            p {
                display: inline;
                margin: 0;
            }
        }
    }

    .content {
        display: flex;
        width: 100%;
        max-width: 100%;

        &.readOnly {
            cursor: initial;
            pointer-events: none !important;
            user-select: none !important;
        }

        figure {
            overflow: hidden;
            max-width: 100%;
            height: max-content;
            margin: 0;
        }

        &.start {
            justify-content: flex-start;
        }

        &.end {
            justify-content: flex-end;
        }

        &.center {
            justify-content: center;
        }
    }

    .required {
        position: absolute;
        display: inline-block;
        border-radius: 50%;
        background: var(--c-blue);
        color: var(--c-white);
        text-align: center;
    }

    .comment {
        position: relative;
        width: 100%;
        font-weight: var(--f-thiner);

        /* &.rowInvalid {

            background: var(--c-red_a_1a);
        } */

        & .commentInvalid {
            border-color: var(--c-red) !important;
            background: var(--c-red_a_1a) !important;
        }
    }

    .order {
        display: inline;
        margin-right: 1em;
        font-size: 0.75em;
    }

    .questionHeader {
        max-width: 100%;

        &.withTimer {
            width: 100%;
        }
    }

    .title {
        width: 100%;
    }

    .disabled {
        pointer-events: none;
    }

    .help figure {
        overflow: hidden;
        max-width: 100%;
        height: max-content;
        margin: 0;
    }
}

.timer {
    font-size: 0.75em;
}

.commentRequiredMark {
    position: absolute;
    top: -0.5em;
    right: -0.5em;
    width: 1em;
    height: 1em;
}

.requiredMark {
    position: absolute;
    width: 0.64em;
    min-width: 10px;
    height: 0.64em;
    min-height: 10px;
}

.requiredTitle {
    position: relative;
    margin-top: -0.25em;
    margin-left: 0.1em;
    line-height: 1;
}

:global(.ttDev) .question .content {
    border: 1px solid var(--c-red);
}

.requiredMarkWrap {
    position: absolute;
    top: -0.5em;
    right: -0.5em;
    width: 1em;
    height: 1em;
}

.comment {
    position: relative;
    width: 100%;
    font-weight: var(--f-normal);

    & .commentInvalid {
        border-color: var(--c-red) !important;
        background: var(--c-red_a_1a) !important;
    }
}
