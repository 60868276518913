.checkbox {
    @mixin flexHList var(--items-gap);
    @mixin flexCenter;
    max-width: 100%;
    cursor: pointer;
    user-select: none;

    & p {
        margin: 0;
    }

    .control {
        --v-size: 1.5em;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        width: var(--v-size);
        min-width: var(--v-size);
        height: var(--v-size);
        min-height: var(--v-size);
        border: 2px solid;
        border-radius: 3px;
        background-color: var(--c-white);
        color: var(--c-blue);
        transition: all ease-in-out 0.1s;

        &.round {
            width: 2em;
            border-radius: 1em;
        }

        svg {
            height: 0.8em;
            visibility: hidden;

            &.visible {
                visibility: visible;
            }
        }

        &.checked {
            background-color: var(--c-blue);

            svg {
                display: flex;
                color: var(--c-white);
            }

            &.disabled {
                background-color: var(--c-grey8D);
            }
        }

        &.disabled {
            color: var(--c-grey8D);
            opacity: 0.3;
        }
    }

    .label {
        font-weight: var(--f-normal);
        line-height: 1.5;

        svg {
            color: var(--c-blue) !important;
        }

        p {
            display: inline-block;
        }
    }
}
