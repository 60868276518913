.testText {
    @mixin flexVList 0;
    width: 100%;
}

.fullWidth {
    width: 100%;
}

.checked {
    @mixin flexVCenter;
    @mixin flexHList var(--items-gap);
}

.results {
    display: flex;
    gap: 1ch;
    justify-content: flex-start;
    margin: 20px 0;
}
